<template>
  <div>
    <spinner-component :loading="loading"></spinner-component>
    <error-dialog-component :model-value="this.errorDialog"></error-dialog-component>
    <v-container>
      <v-row>
        <v-col>
          <router-link :to="{ name: 'new-product' }">

            <v-btn block
                   color="primary"
                   elevation="2"
                   large
            >
              Dodaj nowy produkt
            </v-btn>
          </router-link>
        </v-col>
        <v-col>
          <v-btn block
                 color="primary"
                 elevation="2"
                 large
                 :loading="isSelecting"
                 @click="handleFileImport"
          >
            Importuj produkty jako ZIP
          </v-btn>
          <input
              ref="uploader"
              class="d-none"
              type="file"
              @change="onFileChanged"
          >
        </v-col>
        <v-col>
          <v-btn block
                 color="primary"
                 elevation="2"
                 large
                 @click="exportAllProducts"
          >
            Exportuj wszystkie produkty do ZIP
          </v-btn>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-data-table
              class="elevation-1"
              :headers="headers"
              :items="parsedProducts"
              :search="search"
          >
            <template v-slot:top>
              <v-text-field
                  v-model="search"
                  label="Szukaj"
                  class="mx-4"
              ></v-text-field>
              <v-card flat>
                <v-card-text>
                  <v-container>
                    <v-row>
                      <v-col>
                        <v-select
                            v-model="categories.values"
                            :items="categories.items"
                            attach
                            chips
                            label="Kategoria"
                            multiple
                        ></v-select>
                      </v-col>
                      <v-col>
                        <v-select
                            v-model="types.values"
                            :items="types.items"
                            attach
                            chips
                            label="Typ"
                            multiple
                        ></v-select>
                      </v-col>
                      <v-col>
                        <v-select
                            v-model="statuses.values"
                            :items="statuses.items"
                            attach
                            chips
                            label="Status"
                            multiple
                        ></v-select>
                      </v-col>
                    </v-row>
                    <v-btn
                        @click="applyFilter()"
                    >
                      Filtruj
                    </v-btn>
                  </v-container>
                </v-card-text>
              </v-card>
            </template>
            <template v-slot:[`item.actions`]="{ item }">
              <v-menu offset-y>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                      color="primary"
                      dark
                      v-bind="attrs"
                      v-on="on"
                  >
                    Akcje
                  </v-btn>
                </template>
                <v-list>
                  <v-list-item>
                    <v-list-item-title>
                      <router-link style="text-decoration: none; color: inherit;"
                                   :to="{ name: 'product', params: { productId: item.id } }">
                        <v-btn>Edytuj</v-btn>
                      </router-link>
                    </v-list-item-title>
                  </v-list-item>
                  <v-list-item>
                    <v-list-item-title>
                      <v-btn
                          @click="exportProduct(item)"
                      >
                        Exportuj
                      </v-btn>
                    </v-list-item-title>
                  </v-list-item>
                  <v-list-item>
                    <v-list-item-title>
                      <v-btn
                          @click="removeProduct(item)"
                      >
                        Usuń
                      </v-btn>
                    </v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>

import {productApiClient} from "@/api/product.api.client";
import {saveAs} from "file-saver";
import ErrorDialogComponent from "@/components/ErrorDialogComponent.vue";
import SpinnerComponent from "@/components/SpinnerComponent.vue";

export default {
  name: 'ProductsBaseView',
  inheritAttrs: false,
  components: {SpinnerComponent, ErrorDialogComponent},

  props: {
    status: String
  },

  data() {
    return {
      // MISC
      loading: false,
      errorDialog: {
        errorMessage: "",
        visible: false
      },

      isSelecting: false,
      search: '',
      parsedProducts: [],
      headers: [
        {text: "Typ", value: 'type'},
        {text: "Kategoria", value: 'categories'},
        {text: "Pozycja na liście", value: 'position'},
        {text: "Nazwa", value: 'title'},
        {text: "Sprzedaż", value: 'sold'},
        {text: "Status", value: 'status'},
        {text: "Akcje", sortable: false, value: 'actions'}
      ],
      types: {
        items: ['BH', 'JWB'],
        values: [],
      },
      statuses: {
        items: ['DRAFT', 'PUBLISHED', 'REMOVED'],
        values: [],
      },
      categories: {
        items: ['BOY', 'GIRL', 'BOTH', 'SPECIAL'],
        values: [],
      },
      filter: {
        types: [],
        statuses: [],
        categories: []
      }
    }
  },

  created() {
    this.handleStatus();
  },

  methods: {
    handleStatus() {
      switch (this.status) {
        case 'published':
          this.statuses.values.push('PUBLISHED');
          this.filter.statuses.push('PUBLISHED');
          this.getProducts(this.filter);
          break;
        case 'draft':
          this.statuses.values.push('DRAFT');
          this.filter.statuses.push('DRAFT');
          this.getProducts(this.filter);
          break;
        default:
          this.getProducts(this.filter);
      }
    },

    handleFileImport() {
      this.isSelecting = true;

      window.addEventListener('focus', () => {
        this.isSelecting = false
      }, {once: true});

      this.$refs.uploader.click();
    },
    onFileChanged(e) {
      this.importProducts(e.target.files[0])
    },

    importProducts(zipFile) {
      this.loading = true;
      const formData = new FormData();
      formData.append('file', zipFile);
      productApiClient.importProducts(formData, () => {
        this.handleStatus();
      }, error => {
        this.errorDialog.visible = true;
        this.errorDialog.errorMessage = error.response.data.detail;
      }, () => {
        this.$refs.uploader.value = '';
        this.loading = false;
      })
    },

    exportAllProducts() {
      const ids = this.parsedProducts.map(product => product.id);
      const name = "export"
      this.exportProducts(ids, name);
    },

    exportProduct(items) {
      const ids = [items.id]
      const name = items.id;
      this.exportProducts(ids, name);
    },

    exportProducts(ids, name) {
      this.loading = true;
      productApiClient.exportProducts(ids, response => {
        saveAs(response.data, name + '.zip');
      }, error => {
        this.errorDialog.visible = true;
        this.errorDialog.errorMessage = error.response.data.detail;
      }, () => {
        this.loading = false;
      })
    },

    removeProduct(product) {
      this.loading = true;
      productApiClient.removeProduct(product, () => {
        this.getProducts(this.filter);
      }, error => {
        this.errorDialog.visible = true;
        this.errorDialog.errorMessage = error.response.data.detail;
      }, () => {
        this.loading = false;
      })
    },

    getProducts(filter) {
      this.loading = true;
      productApiClient.getInternalListing(filter, (response) => {
        this.parsedProducts = [];
        response.data.forEach(product => {
          this.parsedProducts.push({
            id: product.id,
            type: product.type,
            categories: product.categories,
            position: product.position,
            title: product.title,
            sold: 0,
            status: product.status
          })
        })
      }, error => {
        this.errorDialog.visible = true;
        this.errorDialog.errorMessage = error.response.data.detail;
      }, () => {
        this.loading = false;
      })
    },

    applyFilter() {
      this.parsedProducts = [];
      this.filter.statuses = this.statuses.values;
      this.filter.types = this.types.values;
      this.filter.categories = this.categories.values;
      this.getProducts(this.filter);
    }
  }
};
</script>
